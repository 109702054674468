.BubbleContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding-bottom: $spacing * 2;

	.BubbleContainer--inner {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;

		@include above-mobile {
			width: 73%;
		}

		@include above-tablet {
			width: 50%;
		}
	}

	// LEFT / RIGHT alignment of ChatBubbles
	&.left .BubbleContainer--inner {
		align-items: flex-start;
	}

	&.right .BubbleContainer--inner {
		align-items: flex-end;
		margin-left: auto;
	}
}

.BubbleContainer.narrow,
.BubbleContainer.heroDialog,
.page--overlay .HeroDialog + .BubbleContainer {
	max-width: $site-width-inner;
	margin-left: auto;
	margin-right: auto;

	@include above-mobile {
		padding-bottom: $spacing;
	}

	.BubbleContainer--inner {
		width: 100%;
	}
}

.BubbleContainer--time {
	@include font-s;
	display: block;
	color: $c-primary;
	margin-bottom: 10px;
}

// ONLY WORKS ON DEV DUE TO THE HTML STRUCTURE THERE.
.BubbleContainer + .Button,
.BubbleContainer + span[id^='sproutencodeemail'] {
	margin-top: $spacing * -2;
}

// HACK FOR PAGE OVERLAY PAGES
.page--overlay .site-content .Button,
.page--overlay .site-content span[id^='sproutencodeemail'] {
	align-self: flex-end;
}

// TEAM PAGE: Remove space between containers
.page--team-members .Filter--Search ~ .BubbleContainer {
	padding-bottom: 0;
}

// Mainly used for fake pagination on Team/Stories pages
.BubbleContainer.hide,
.BubbleContainer.teaser.hide {
	display: none;
}
