/* Bubble Base Styles */

@mixin BubbleMedia {
	@include border-radius-theme;
	overflow: hidden;
	position: relative;
	margin-bottom: $gutter;

	// Right aligned bubble
	.BubbleContainer.right & {
		@include border-radius-theme(reverse);
	}

	// For first bubble, add border-radius
	.BubbleContainer.left &:first-of-type {
		border-top-left-radius: 30px;
	}

	.BubbleContainer.right &:first-of-type {
		border-top-right-radius: 30px;
	}

	img {
		width: 100%;
	}
}
