$c-white: rgb(255, 255, 255);
$c-black: rgb(0, 0, 0);

$c-grey: rgb(133, 133, 133);
$c-grey--dark: rgb(42, 45, 52);
$c-grey--light: rgb(242, 242, 242);
$c-grey--medium: rgb(63, 66, 72);
$c-primary: #e7344f;
$c-primary--dark: #a02739;
$c-offwhite: #f2f2f2;
$c-secondary: #18394c;
$c-secondary--dark: #112e3f;

$c-primary: #666b3f;
$c-primary--dark: #3e4317;

$c-secondary: #484848;
$c-secondary--dark: #353535;

$plyr-color-main: $c-primary !default;
