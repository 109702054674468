/*  
    BubbleH2 Component Styles
    @animatable true
*/

.BubbleH2 {
    @include Bubble;
    @include mobile {
        width: 100%;
    }

    span {
        @include font-xxs;
        color: $c-grey;
    }

    span + span:before {
        content:"•";
        margin: 0 10px;
    }

    h2 {
        @include h2;
        
        @include mobile {
            @include hyphenate;
        }        
	}
    
    .BubbleContainer.left & {
        @include above-mobile {
            margin-right: -10vw;
        }
    }

    .BubbleContainer.right & {
        @include above-mobile {
            margin-left: -10vw;
        }
    }
}