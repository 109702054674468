.FormRow {
	display: block;
	overflow: hidden;
	margin-bottom: 20px;

	.l-FlexGrid & {
		margin-bottom: 0;
	}
}

.FormRow {

	legend {
		@include font-m;
		margin-bottom: $gutter;
	}

	p {
		@include font-xxs;
		color: $c-grey;
		margin-bottom: 1em;

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	p:empty {
		margin-bottom: 0em;
	}

	.Button[type='submit'] {
		margin-bottom: 2.5rem;
	}
}

.FormRow--file + .FormRow--file {
	margin-top: -40px;
}