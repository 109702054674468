.color-list {
	@include list-reset;

	max-width: 800px;
	overflow: hidden;
}

.color-list__item {
	position: relative;
	float: left;
	min-width: 160px;
	text-align: center;
	margin-bottom: rem(32);
	margin-right: rem(32);
}

.color-list__sample {
	position: relative;
	margin-bottom: rem(12);
	border: 4px solid rgb(200, 200, 200);
	border-radius: 4px;

	&::before {
		display: block;
		content: '';
		width: 100%;
		padding-top: (1 / 1) * 100%;
	}
}
