/* Hero Component Styles */
.HeroImage {
    position: relative;
    margin-top: -$gutter*10;
    margin-bottom: $spacing*2;

    @include mobile {
        @include fullWidth;
        margin-top: -$gutter*4;
        margin-bottom: 0;
    }    

    img,
    video {
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: 0;

        @include above-mobile {
            width: calc(100% - 80px);   
            margin-left: 80px;
            border-radius: 30px;
        }
    }    
}

.HeroImage + .BubbleContainer--inner {
    position: relative;
    margin-top: -50px;
    @include above-mobile {
        margin-top: -25%;
    }
}