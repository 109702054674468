/* TextContainer Component Styles */
.TextContainer {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing*3;
    max-width: $site-width-inner;

    &:not(:last-child) {
        margin-bottom: $spacing;
    }
}
