/* Header Component Styles */
html.menu-is-open {
	overflow: hidden;
}

// HEADER Modules layout
.Header {
	padding-top: $gutter;
	padding-bottom: $gutter;
	margin-bottom: $gutter;

	@include above-mobile {
		padding-top: $gutter * 2;
		padding-bottom: $gutter * 3.5;
	}

	@include above-tablet {
		padding-bottom: $gutter * 2;
	}

	.HeaderBurger {
		position: absolute;
		z-index: 20;
		@include above-tablet-portrait {
			position: fixed;
			z-index: 30;
		}
	}

	.HeaderNavOverlay {
		position: absolute;
		z-index: 20;
		overflow-x: hidden;
		overflow-y: auto;
		@include above-tablet-portrait {
			position: fixed;
			overflow: visible;
		}
	}

	//
	.HeaderMute {
		position: absolute;
		z-index: 30;
		display: none;

		@include above-mobile {
			position: fixed;
			z-index: 10;
			display: flex;
		}
	}
}

body.page--overlay .Header {
	margin-bottom: 0;

	@include above-mobile {
		margin-bottom: -$gutter * 3;
	}
}
