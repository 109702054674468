
// =================================
// =================================
// Chocolat plugin styling
.chocolat-zoomable.chocolat-zoomed {
    cursor: zoom-out;
}
.chocolat-open {
    overflow: hidden;
}
.chocolat-overlay {
    // transition: opacity 0.4s ease, visibility 0s 0.4s ease;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
}
.chocolat-overlay.chocolat-visible {
    // transition: opacity 0.4s, visibility 0s;
    visibility: visible;
    opacity: 1;
}

.chocolat-wrapper {
    // transition: opacity 0.4s ease, visibility 0s 0.4s ease;
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 99;
    color: #fff;
    display: none;
}
.chocolat-wrapper.chocolat-visible {
    // transition: opacity 0.4s, visibility 0s;
    display: block;
    opacity: 1;
}

.chocolat-zoomable .chocolat-img {
    cursor: zoom-in;
}
.chocolat-loader {
    // transition: opacity 0.3s;
    height: 32px;
    width: 32px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -16px;
    margin-top: -16px;
    z-index: 11;
    // background: url(../images/loader.gif);
    opacity: 0;
}
.chocolat-loader.chocolat-visible {
    opacity: 1;
}

.chocolat-image-wrapper {
    position: fixed;
    width: 0px;
    height: 0px;
    left: 50%;
    top: 50%;
    z-index: 14;
    text-align: left;
    transform: translate(-50%, -50%);
}

.chocolat-image-wrapper .chocolat-img {
    position: absolute;
    width: 100%;
    height: 100%;
}
.chocolat-wrapper .chocolat-left {
    width: 50px;
    height: 100px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M13.2928932 5.29289322c.3905243-.39052429 1.0236893-.39052429 1.4142136 0L21.4142136 12l-6.7071068 6.7071068c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L17.585 13H4c-.51283584 0-.93550716-.3860402-.99327227-.8833789L3 12c0-.5522847.44771525-1 1-1h13.585l-4.2921068-4.29289322c-.3604839-.36048396-.3882135-.92771502-.0831886-1.32000622z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 16px auto;
    transform: rotateY(180deg);
    z-index: 17;
    visibility: hidden;
    display: none;
}

.chocolat-layout {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.chocolat-image-canvas {
    // transition: opacity .2s;
    opacity: 0;
    flex-grow: 1;
    align-self: stretch;
}
.chocolat-image-canvas.chocolat-visible {
    opacity: 1;
}
.chocolat-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chocolat-wrapper .chocolat-right {
    width: 50px;
    height: 100px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M13.2928932 5.29289322c.3905243-.39052429 1.0236893-.39052429 1.4142136 0L21.4142136 12l-6.7071068 6.7071068c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L17.585 13H4c-.51283584 0-.93550716-.3860402-.99327227-.8833789L3 12c0-.5522847.44771525-1 1-1h13.585l-4.2921068-4.29289322c-.3604839-.36048396-.3882135-.92771502-.0831886-1.32000622z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;    
    background-size: 16px auto;
    z-index: 17;
    visibility: hidden;
    display: none;
}
.chocolat-wrapper .chocolat-right.active {
    visibility: visible;
}
.chocolat-wrapper .chocolat-left.active {
    visibility: visible;
}
.chocolat-wrapper .chocolat-top {
    height: 50px;
    overflow: hidden;
    z-index: 17;
    flex-shrink: 0;
}
.chocolat-wrapper .chocolat-close {
    width: 50px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M5.75443168 5.75443168c.39052429-.39052429 1.02368927-.39052429 1.41421356 0l4.83089326 4.83010682 4.8318163-4.83010682c.3905242-.39052429 1.0236892-.39052429 1.4142135 0 .3905243.39052429.3905243 1.02368927 0 1.41421356l-4.8310298 4.83089326 4.8310298 4.8318163c.360484.3604839.3882135.927715.0831886 1.3200062l-.0831886.0942073c-.3905243.3905243-1.0236893.3905243-1.4142135 0l-4.8318163-4.8310298-4.83089326 4.8310298c-.39052429.3905243-1.02368927.3905243-1.41421356 0-.39052429-.3905243-.39052429-1.0236893 0-1.4142135l4.83010682-4.8318163-4.83010682-4.83089326c-.36048396-.36048396-.3882135-.92771501-.08318861-1.32000622z'/%3E%3C/svg%3E");    
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 16px auto;
}
.chocolat-wrapper .chocolat-bottom {
    height: 40px;
    font-size: 12px;
    z-index: 17;
    padding-left: 15px;
    padding-right: 15px;
    background: rgba(0, 0, 0, 0.2);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    background: none;
    color: #000;
    visibility: hidden;
    opacity: 0;

}
.chocolat-wrapper .chocolat-set-title {
    display: inline-block;
    padding-right: 15px;
    line-height: 1;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    display: none;
}
.chocolat-wrapper .chocolat-pagination {
    float: none;
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    /*border-right: 1px solid rgba(255, 255, 255, 0.2);*/
}
.chocolat-wrapper .chocolat-fullscreen {
    width: 16px;
    height: 40px;
    // background: url(../images/fullscreen.png) 50% 50% no-repeat;
    display: block;
    cursor: pointer;
    float: right;
    display: none;
}
.chocolat-wrapper .chocolat-description {
    display: inline-block;
    flex-grow: 1;
    text-align: left;
    display: none;
}

/* no container mode*/
body.chocolat-open > .chocolat-overlay {
    z-index: 15;
}
body.chocolat-open > .chocolat-loader {
    z-index: 15;
}
body.chocolat-open > .chocolat-image-wrapper {
    z-index: 17;
}

/* container mode*/
.chocolat-in-container .chocolat-wrapper,
.chocolat-in-container .chocolat-image-wrapper,
.chocolat-in-container .chocolat-overlay {
    position: absolute;
}
.chocolat-in-container {
    position: relative;
}

.chocolat-zoomable.chocolat-zooming-in .chocolat-image-wrapper,
.chocolat-zoomable.chocolat-zooming-out .chocolat-image-wrapper {
    // transition: width .2s ease, height .2s ease;
}
.chocolat-zoomable.chocolat-zooming-in .chocolat-img,
.chocolat-zoomable.chocolat-zooming-out .chocolat-img {
    // transition: margin .2s ease;
}

/* uncomment to hide controls when zoomed-in*/
/*
.chocolat-zoomable .chocolat-top,
.chocolat-zoomable .chocolat-bottom,
.chocolat-zoomable .chocolat-right,
.chocolat-zoomable .chocolat-left {
    transition: opacity .3s ease, visibility 0s .3s;
   opacity: 1;
}

.chocolat-zoomable.chocolat-zoomed .chocolat-top,
.chocolat-zoomable.chocolat-zoomed .chocolat-bottom,
.chocolat-zoomable.chocolat-zoomed .chocolat-right,
.chocolat-zoomable.chocolat-zoomed .chocolat-left {
    visibility: hidden;
    opacity: 0;
}
*/
