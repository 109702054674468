/* Divider Component Styles */
.Divider {
    position: relative;
    margin-bottom: $spacing;
    text-align: center;
    @include above-mobile {
        margin-bottom: $spacing*3;
    }

    span {
        @include font-s;
        position: relative;
        z-index: 2;
        background: $c-offwhite;
        display: inline-block;
        padding-top: $gutter*0.6;
        padding-right: $gutter;
        padding-bottom: $gutter*0.5;
        padding-left: $gutter;    
        border-radius: 50px;
        border: 2px solid lighten($c-grey,30%);
        color: $c-grey;
        @include above-mobile {
            padding-top: $gutter*0.8;
            padding-right: $gutter*1.2;
            padding-bottom: $gutter*0.7;
            padding-left: $gutter*1.2;            
        }
    }

    &:before,
    &:after {
        content:"";
        width: 50%;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        background-image: linear-gradient(to left, rgba($c-black,0) 0%, lighten($c-grey,30%) 80%);

        @include above-mobile {
            width: 30%;
        }
    }

    &:after {
        left: auto;
        right: 50%;
        background-image: linear-gradient(to right, rgba($c-black,0) 0%, lighten($c-grey,30%) 80%);
    }
}

.Divider.small {
    margin-bottom: $gutter*1;

    &:before,
    &:after {
        width: 10%;
    }
}