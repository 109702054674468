/* HeaderBurger Component Styles */
.HeaderBurger {
	top: 43px;
	right: 20px;
	width: 36px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@include above-mobile {
		top: 63px;
		right: 40px;
	}

	@include above-tablet {
		top: 46%;
		left: 2rem;
		transform: translateY(-50%);
	}

	p {
		font-size: 12px;
		line-height: 1;
		margin-top: 10px;
		color: $c-primary;
		transition: $transition-duration-default color;
	}

	span {
		display: block;
		height: 2px;
		width: 36px;
		background: $c-primary;
		border-radius: 2px;
		transition: transform 0.45s cubic-bezier(0.075, 0.82, 0.165, 1), 0.2s background;

		@include above-mobile {
			width: 36px;
		}
	}

	span + span {
		margin-top: 7px;
	}

	// Active state
	.HeaderNav.is-open & {
		span:nth-child(1) {
			transform: translateY(9px);
		}
		span:nth-child(3) {
			transform: translateY(-9px);
		}
	}

	// Hover state
	&:hover {
		@media (hover: hover) {
			span:nth-child(1) {
				transform: translateY(9px);
			}
			span:nth-child(3) {
				transform: translateY(-9px);
			}
		}
	}

	@include mobile-old {
		margin-top: -7px;
		transform: scale(0.8);
		transform-origin: top right;
	}
}

.HeaderNav.is-open .HeaderBurger {
	p {
		color: $c-white;
	}

	span {
		background: $c-white;
	}
}

// Text change menu open/close
.HeaderNav .HeaderBurger--text-close {
	display: none;
	position: relative;
}
.HeaderNav.is-open .HeaderBurger--text-open {
	display: none;
}
.HeaderNav.is-open .HeaderBurger--text-close {
	display: block;
}

// Burger over footer
.HeaderBurger.is-over-footer {
	p {
		color: $c-white;
	}

	span {
		background: $c-white;
	}
}
