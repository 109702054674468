/* HeaderSearch Component Styles */
html.search-is-open {
	overflow: hidden;
}

.HeaderSearch {
	visibility: hidden;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 40;
	transition: visibility 0.4s linear;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $c-secondary--dark;
		opacity: 0;
		transition: opacity $transition-duration-default ease-in-out;
		cursor: pointer;
	}

	&.open {
		visibility: visible;
		pointer-events: auto;
		transition-duration: 0s;

		&:before {
			opacity: 0.9;
		}
	}
}

.HeaderSearch--inner {
	background: $c-white;
	position: relative;
	transform: translateY(-101%);
	transition: transform $transition-duration-default ease;

	.HeaderSearch.open & {
		transform: none;
	}
}

.HeaderSearch--content {
	opacity: 0;

	.HeaderSearch.open & {
		opacity: 1;
		transition: opacity $transition-duration-default $transition-duration-default ease-in-out;
	}
}

.HeaderSearch--content--inner {
	display: flex;
	flex-direction: column;
}

.HeaderSearch--form {
	display: flex;
	align-items: center;
	padding: 100px 0;

	@include tablet {
		padding: 60px 0;
	}
}

.HeaderSearch--form--input {
	width: 100%;
	flex-grow: 1;
	border: none;
	background: $c-white;
	border-radius: 0;
	font-size: 24px;
	border: 0;
	-webkit-appearance: none;

	&:-webkit-autofill {
		box-shadow: 0 0 0 50px #fff inset;
	}

	&::placeholder {
		color: #9e9e9e;
	}
}

.HeaderSearch--form--button {
	flex-shrink: 0;
	padding: 1em;
	margin: -1em;
	margin-left: 0;

	.SvgIcon {
		@include tablet {
			height: 24px;

			> svg {
				height: 24px;
			}
		}
	}
}

.HeaderSearch--close {
	order: -1;
	padding: 1em;
	margin: -1em;
	margin-top: calc(-1em + #{$gutter});
	margin-left: auto;
	align-self: flex-end;

	.SvgIcon {
		@include tablet {
			height: 24px;

			> svg {
				height: 24px;
			}
		}
	}

	@include above-mobile {
		margin-top: calc(-1em + #{$gutter * 2});
	}
}
