.FormInner {
	max-width: $site-width-inner;
    margin-left: auto;
    margin-right: auto;
}

input,
textarea {
	font-size: 16px;

	&:focus {
		border-color: #000;
	}
}

select {
	padding: 5px;
	border: none;
}

.FormInput {
	display: block;
	margin-bottom: 1.5ex;

	> label {
		display: block;
		margin-bottom: 0.2ex;
	}
}

label > abbr {
	border: none;
}