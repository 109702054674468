.richtext {
	@include richtext(most);
}

.t-nowrap {
	@include nowrap;
}

.t-hyphenate {
	@include hyphenate;
}
