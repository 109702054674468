$font-regular: 'Graphik Regular', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-regular-italic: 'Graphik Regular Italic', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-medium: 'Graphik Medium', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-bold: 'Graphik Semibold', 'Helvetica Neue', 'Helvetica', sans-serif;

$base-font: $font-medium;

$base-font-size: 22px;
//$body-font-size: rem(18); // This value reflects the desired 'base' font size of the theme.
$base-line-height: 32px;
$base-font-color: $c-black;
$base-font-family: $base-font;

// Grid settings
$site-width: 1200px;
$site-width-inner: 700px;
$gutter: 20px;
$side-padding: 16px;
$grid-columns: 12;
$spacing: 40px;

// Transition durations
$transition-duration-very-slow: 0.5s;
$transition-duration-slow: 0.3s;
$transition-duration-default: 0.2s;
$transition-duration-fast: 0.1s;

// Easings
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
