@mixin list-reset {
	list-style: none;
	padding: 0;
	margin: 0;
}

@mixin object-fit-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;

	/* stylelint-disable */
	font-family: 'object-fit: cover'; // for polyfill
	/* stylelint-enable */
}

@mixin textantialiasing() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin hyphenate {
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	hyphenate-limit-chars: 10 4 4;
}

@mixin text-selection {
	// Set custom background and font color fot text selection.
}

/**
 * Hide only visually, but have it available for screen readers:
 * See http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
@mixin visuallyhidden {
	position: absolute;
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	width: 1px;
	white-space: nowrap; /* https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe */
}

@mixin reset-visuallyhidden {
	position: static;
	clip: auto;
	clip-path: none;
	height: auto;
	margin: 0;
	overflow: visible;
	width: auto;
	white-space: inherit;
}

@mixin fullWidth {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}

// IE11 specific
@mixin target-metro {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}
