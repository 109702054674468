// Add font-face definitions here
@font-face {
	font-family: 'Graphik Regular';
	src: local('Graphik Regular'), local('Graphik Regular'), url('../fonts/Graphik-Regular.woff') format('woff');
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik Regular Italic';
	src: local('Graphik Regular Italic'), local('Graphik Regular Italic'),
		url('../fonts/Graphik-RegularItalic.woff') format('woff');
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik Medium';
	src: local('Graphik Medium'), local('Graphik Medium'), url('../fonts/Graphik-Medium.woff2') format('woff2'),
		url('../fonts/Graphik-Medium.woff') format('woff');
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Graphik Semibold';
	src: local('Graphik Semibold'), local('Graphik Semibold'), url('../fonts/Graphik-Semibold.woff2') format('woff2'),
		url('../fonts/Graphik-Semibold.woff') format('woff');
	font-weight: normal;
	font-display: swap;
}
