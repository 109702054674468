/* LoadMore Component Styles */
.LoadMore{
    text-align: center;

    .Button--Icon {
        transform: rotate(90deg);
    }

    .Button.hide {
        display: none;
    }
}