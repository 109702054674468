/* ShareButtons Component Styles */

.ShareButtons {
    position: absolute;
    top: -28px;
    right: 9px;

    @include above-mobile {
        transform: translateY(50%);
        top: auto;
        bottom: 0;
        right: 15px;
    }
    
    @include above-tablet {
        right: 5%;
    }

    .Button {
        margin: 0;
    }

    .ShareButtons--Dropdown {
        margin-left: 5px;
        @include above-mobile {
            margin-left: 10px;
        }
    }
}

.ShareButtons--Dropdown {
    position: relative;
    display: inline-block;
}

.ShareButtons--Dropdown-List {
    position: absolute;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    @include above-mobile {
        bottom: 100%;
        top: auto;
    }

    .Button {
        opacity: 0;
        transition: all 0.15s;
        transform: translateY(5px);
        margin-top: 5px;
        @include above-mobile {
            margin-bottom: 10px;
            margin-top: 0;
        }
    }
}

.ShareButtons--Dropdown:hover .ShareButtons--Dropdown-List {
    opacity: 1;
    visibility: visible;

    .Button {
        opacity: 1;
        transform: translateY(0px);

        @include mobile {
            @for $i from 1 through 5 {
                &:nth-of-type(#{$i}) {
                    transition-delay: 0s + ($i / 20);
                }
            }
        }

        @include above-mobile {
            @for $i from 1 through -5 {
                &:nth-of-type(#{$i}) {
                    transition-delay: 0s + ($i / 20);
                }
            }
        }
    }
}