@mixin body-font {
	font-family: $base-font;
}

@mixin font-xxl {
	@include font(bold);

	font-size: 50px;
	line-height: 1.2;

	@include mobile {
		font-size: 28px;
	}
}

@mixin font-xl {
	@include font(bold);

	font-size: 40px;
	line-height: 1.1;

	@include mobile {
		font-size: 44px;
	}

	@include mobile-narrow {
		font-size: 34px;
	}
}

@mixin font-l {
	@include font(bold);

	font-size: 30px;
	line-height: 40px;
}

@mixin font-m {
	font-size: 24px;
	line-height: 32px;
}

@mixin font-s {
	font-size: 12px;
	line-height: 1.4;
	@include above-mobile {
		font-size: 16px;
	}
}

@mixin font-base {
	font-size: $base-font-size * 0.75;
	line-height: $base-line-height * 0.75;

	@include above-mobile {
		font-size: $base-font-size;
		line-height: $base-line-height;
	}
}

@mixin font-xs {
	font-size: 16px;
	line-height: 1.5;
}

@mixin font-xxs {
	font-size: 14px;
	line-height: 1.6;
}

@mixin h1 {
	@include font-xxl;
}

@mixin h2 {
	@include font-l;
}

@mixin h3 {
	@include font-m;
}

@mixin h4 {
	@include font-s;
}

@mixin paragraph {
	margin-top: 1em;
	margin-bottom: 1em;
}

@mixin strong {
	@include font(bold);
}

@mixin a {
	color: #248abc;

	&:hover {
		color: black;
	}
}

@mixin pre {
	padding: 20px;
	background: $c-white;
}

@mixin code {
	background: white;
}

@mixin ul {
	@include rem(margin-left, -30px);

	display: block;

	> li {
		@include rem(padding-left, 30px);

		position: relative;
		list-style-type: none;
		margin-bottom: 1.5ex;

		&:before {
			position: absolute;
			left: 0;
			display: inline-block;
			content: '\2013';
		}
	}
}
@mixin ol {
	@include ul;

	display: block;
	counter-reset: ol--counter;

	> li {
		list-style-type: none;

		&:before {
			@include rem(left, -30px);

			counter-increment: ol--counter;
			content: counter(ol--counter) '.';
			text-align: right;
			width: 4ex;
		}
	}

	/*html*/

	.ie & {
		> li {
			list-style: decimal inside none;
		}
	}
}

@mixin table {
	border-collapse: collapse;
	border-spacing: 0;
}

@mixin richtext($include: most) {
	@if $include == all {
		h1 {
			@include h1;

			margin-bottom: 0.5ex;
		}

		h2 {
			@include h2;

			margin-top: 1.5em;
			margin-bottom: 0.5ex;
		}

		h3 {
			@include h3;

			margin-top: 1.5em;
			margin-bottom: 0.5ex;
		}

		pre {
			@include pre;

			margin: 1em 0;
		}

		code {
			@include code;
		}

		pre code,
		pre pre,
		code pre {
			background: transparent;
		}
	}

	@if $include == most or $include == all {
		table {
			@include table;
		}

		p {
			@include paragraph;
		}

		ul {
			@include ul;

			margin: 1em 0;
		}

		ol {
			@include ol;

			margin: 1em 0;
		}
	}

	strong {
		@include strong;
	}

	a {
		@include a;
	}
}

@mixin nowrap {
	white-space: nowrap;
}

// @mixin hyphenate {
// 	hyphens: auto;
// }

@mixin overflow-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
