.ScrollToTop {
	position: fixed;
	bottom: 40px;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	z-index: 10;
	opacity: 0;
	margin-bottom: -20px;
	transition: opacity 0.3s, margin 0.3s;	

	&.show {
		opacity: 1;
		margin-bottom: 0;
	}
	
	@include mobile {
		bottom: 20px;
	}
	
	&.not-sticky {
		position: absolute;
		bottom: auto;
		top: 0;
		transform: translateY(calc(-100% - 40px)) translateX(-50%);
		@include mobile {
			transform: translateY(calc(-100% - 20px)) translateX(-50%);
		}		
	}

	.Button {
		font-size: 14px;
		line-height: 24px;
		margin: 0;
		white-space: nowrap;
		padding-left: 25px;
		padding: 9px 12px 9px 23px;

		@include above-mobile{
			font-size: 16px;
			padding: 17px 20px 19px 28px;
		}

		&:hover .Button--Icon {
			margin-left: 10px !important;
		}
	}

	.Button .Button--Icon {
		height: auto;
		width: auto;
		background: none;
		transform: rotate(-90deg);
		margin-left: 10px;

		svg {
			@include above-mobile {
				height: 1.2em;
			}
		}
	}
}