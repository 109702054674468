html {
	width: 100%;
	font-size: 16px; // uppercase PX won't be translated to rem
	text-size-adjust: 100%;

	@media (max-width: #{($mq-tablet + $mq-desktop) / 2}) {
		font-size: 0.85 * 16px;
	}

	@media (max-width: #{($mq-tablet-portrait + $mq-tablet) / 2}) {
		font-size: 0.8 * 16px;
	}

	@media (max-width: #{($mq-mobile + $mq-tablet-portrait) / 2}) {
		font-size: 0.75 * 16px;
	}

	@media (max-width: #{($mq-mobile-portrait + $mq-mobile) / 2}) {
		font-size: 0.7 * 16px;
	}

	box-sizing: border-box;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	background: $c-offwhite;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	font-family: $base-font;
	font-size: $base-font-size;
	line-height: $base-line-height;
	overflow-x: hidden;

	&.page--overlay {
		background: $c-white;
	}
}

::-moz-selection {
	@include text-selection;
}

::selection {
	@include text-selection;
}

body,
input,
select,
button,
textarea {
	@include textantialiasing;
}

a {
	color: inherit;
	text-decoration: none;
}

button {
	appearance: none;
	border: 0;
	background: none;
	cursor: pointer;
}

input,
select,
textarea {
	appearance: none;
}

h1 {
	margin: 0; // reset margin set by normalize.css
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

.site-content {
	max-width: 100%;
}

.js-randomBgWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	z-index: -1;
	pointer-events: none;

	&.anim-left .js-randomBgWrapper--line {
		transform: translateX(450%) scaleX(0);
	}
	&.anim-right .js-randomBgWrapper--line {
		transform: translateX(-450%) scaleX(0);
	}
}

.js-randomBgWrapper--line {
	position: absolute;
	height: 8px;
	right: 100px;
	width: 70px;
	background: green;
	display: block;
	transform: translateX(450%) scaleX(0);
	z-index: -1;

	.inNavOverlay & {
		opacity: 0.1;
	}
}

.visuallyhidden {
	@include visuallyhidden;
}

mark {
	background: yellow;
}
