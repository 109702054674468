.svg-Anhang-dims {
	width: 24px;
	height: 24px;
}

.svg-Arrow-dims {
	width: 24px;
	height: 24px;
}

.svg-ArrowSimple-dims {
	width: 24px;
	height: 24px;
}

.svg-Bullet-dims {
	width: 24px;
	height: 24px;
}

.svg-Call-dims {
	width: 24px;
	height: 24px;
}

.svg-Chat-dims {
	width: 24px;
	height: 24px;
}

.svg-Check-dims {
	width: 24px;
	height: 24px;
}

.svg-Close-dims {
	width: 24px;
	height: 24px;
}

.svg-ContactSupport-dims {
	width: 24px;
	height: 24px;
}

.svg-Download-dims {
	width: 24px;
	height: 24px;
}

.svg-Edit-dims {
	width: 24px;
	height: 24px;
}

.svg-Event-dims {
	width: 24px;
	height: 24px;
}

.svg-ExternalLink-dims {
	width: 24px;
	height: 24px;
}

.svg-Fachberichte-dims {
	width: 24px;
	height: 24px;
}

.svg-Link-dims {
	width: 24px;
	height: 24px;
}

.svg-LinkedIn-dims {
	width: 24px;
	height: 24px;
}

.svg-Location-dims {
	width: 24px;
	height: 24px;
}

.svg-Mail-dims {
	width: 24px;
	height: 24px;
}

.svg-News-dims {
	width: 24px;
	height: 24px;
}

.svg-Osterreich-dims {
	width: 22px;
	height: 22px;
}

.svg-Plus-dims {
	width: 24px;
	height: 24px;
}

.svg-Schweiz-dims {
	width: 23px;
	height: 22px;
}

.svg-Search-dims {
	width: 24px;
	height: 24px;
}

.svg-Share-dims {
	width: 24px;
	height: 24px;
}

.svg-Support-dims {
	width: 24px;
	height: 24px;
}

.svg-Twitter-dims {
	width: 24px;
	height: 24px;
}

.svg-Upload-dims {
	width: 24px;
	height: 24px;
}

.svg-Whatsapp-dims {
	width: 24px;
	height: 24px;
}

.svg-Xing-dims {
	width: 24px;
	height: 24px;
}

