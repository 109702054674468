/* ResultList Component Styles */

.ResultList {
}

.ResultList--list {
	max-width: 990px;
	margin: 0 auto;
	margin-bottom: 3em;

	@include mobile {
		margin-bottom: 2em;
	}
}

.ResultList--list-item {
	margin-bottom: 1em;

	&.hide {
		display: none;
	}
}

.ResultList--card {
	display: block;
	background: $c-white;
	border-radius: 20px;
	padding: 30px 40px;
	transition: $transition-duration-default box-shadow;

	&:hover {
		box-shadow: 0 20px 35px -18px rgba(0, 0, 0, 0.2);
	}

	@include mobile {
		padding: 30px;
	}
}

.ResultList--card-title {
	@include font-base;
	margin-bottom: 1em;
}

.ResultList--card-text {
	@include font-s;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	margin-bottom: 1em;

	@include mobile {
		-webkit-line-clamp: 4;
	}
}

.ResultList--card-path {
	font-size: 12px;
	line-height: 1.4;
	color: $c-grey;
	word-wrap: break-word;
}
