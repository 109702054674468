/* BubbleGrid Component Styles */
// Base layout
.BubbleGrid {
	overflow: hidden;
	margin-bottom: $spacing * 2;

	@include above-mobile {
		overflow: visible;
	}

	.BubbleContainer {
		padding-bottom: $gutter;
	}
}

.BubbleGrid--inner {
	padding-bottom: 20px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -10px;

	.BubbleGrid--item {
		margin: 0 10px 20px;
		width: calc(50% - 20px);
		padding-bottom: 60%;

		@include above-tablet-portrait {
			width: calc(33.33333% - 20px);
			padding-bottom: 35%;
		}
	}
}

// Slider item styling
.BubbleGrid--item {
	position: relative;
	background: $c-white;
	border-radius: 30px;
	height: 0;
	overflow: hidden;

	.BubbleGrid--item-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: $gutter * 0.5;
		display: flex;
		flex-direction: column;
		@include above-mobile {
			padding: $gutter * 1;
		}
	}
}
// Slider item Elements: Image
.BubbleGrid--item .Image {
	width: 70%;
	height: 80px;
	object-fit: contain;
	margin: auto;
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
	@include above-mobile {
		height: 180px;
	}
}

// Slider item Elements: Title
.BubbleGrid--item--title {
	@include font-s;
	color: $c-primary;
}

// Slider item Elements: Links on hover
.BubbleGrid--item--links {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: $gutter * 0.5;
	background: rgba(0, 0, 0, 0.6);
	opacity: 0;
	transition: 0.5s opacity;
	border-radius: 30px;

	@include above-mobile {
		padding: $gutter * 2;
	}

	a {
		opacity: 0;
		transform: translateY(5px);
		transition: 0.2s all;
		will-change: transform, opacity;

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				transition-delay: $i * 0.1 + s;
			}
		}
	}

	a:last-child {
		margin-bottom: 0;
	}
}

// Slider item Elements: Hover state links
.BubbleGrid--item:not([aria-hidden]):hover {
	.BubbleGrid--item--links {
		opacity: 1;
	}

	.BubbleGrid--item--links a {
		opacity: 1;
		transform: translateY(0);
	}
}

.BubbleGrid.has-numbers {
	.BubbleGrid--inner {
		position: relative;
		counter-reset: gridcounter;
	}

	.BubbleGrid--item:before {
		counter-increment: gridcounter;
		content: counter(gridcounter);
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 99;
		@include font-xxs;
		background: $c-primary;
		color: $c-white;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 50%;
		display: block;
		text-align: center;

		@include above-mobile {
			top: 20px;
			right: 20px;
			@include font-xs;
			width: 50px;
			height: 50px;
			line-height: 50px;
		}
	}
}
