/*  
    Component that animate
*/

html.js main.site-content .BubbleContainer--inner > {
	.BubbleH1,
	.BubbleH2,
	.BubbleImage,
	.Button,
	.BubbleBlockquote,
	.BubbleText,
	.BubbleVideoWrapper {
		opacity: 0;
		transform: translateY(15px);

		&.is-visible {
			opacity: 1;
			transform: translateY(0px);
			transition: 0.35s transform $ease-out-cubic, 0.35s opacity $ease-out-cubic;

			@for $i from 1 through 10 {
				&:nth-of-type(#{$i}) {
					transition-delay: 0.2s + ($i / 7);
				}
			}
		}
	}

	.Bubble--idle {
		display: flex;
	}
}

.Bubble--idle {
	position: absolute;
	top: 2rem;
	left: 0;
	background: $c-primary;
	display: none;
	align-items: center;
	justify-content: center;
	width: 110px;
	height: 54px;
	@include above-mobile {
		width: 140px;
		height: 70px;
	}

	.BubbleContainer.left & {
		left: 0;
		border-radius: 24px 24px 24px 0;
		@include above-mobile {
			border-radius: 30px 30px 30px 0;
		}
	}

	.BubbleContainer.right & {
		left: auto;
		right: 0;
		border-radius: 24px 24px 0px 24px;
		@include above-mobile {
			border-radius: 30px 30px 0px 30px;
		}
	}

	span {
		position: relative;
		display: inline-block;
		height: 10px;
		width: 10px;
		background: white;
		margin: 0 6px;
		border-radius: 50%;
		animation-duration: 0.6s;
		animation-name: pulse;
		animation-iteration-count: infinite;
		animation-direction: alternate;
		transition: all 0.2s $ease-out-cubic;
		@include above-mobile {
			height: 16px;
			width: 16px;
		}
	}

	@for $i from 1 through 3 {
		span:nth-of-type(#{$i}) {
			animation-delay: -1.2s - ($i / 0.98);
		}
	}
}

@keyframes pulse {
	from {
		opacity: 1;
		transform: scale(1);
	}
	to {
		opacity: 0.2;
		transform: scale(0.5);
	}
}

.Bubble--idle.is-hidden {
	transition: 0.35s transform cubic-bezier(0.6, -0.28, 0.735, 0.045),
		0.35s opacity cubic-bezier(0.6, -0.28, 0.735, 0.045);
	transform: scale(0) rotate(-6deg);
	transform-origin: 0 100%;
	opacity: 0;

	.BubbleContainer.right & {
		transform-origin: 100% 100%;
	}
}
