/*
    Avatar Component Styles
    @animatable true
*/

// Base layout
.Avatar {
	position: relative;
	display: flex;
	align-items: center;
	align-content: flex-start;

	&--image {
		box-sizing: content-box;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		object-fit: cover;
		object-position: 50% 50%;
		flex: 0 0 50px;

		-ms-interpolation-mode: nearest-neighbor;

		@include target-metro {
			border-radius: 0;
		}

		@include above-mobile {
			width: 70px;
			height: 70px;
			flex: auto;
		}

		@supports (-moz-appearance: none) and (max-width: $mq-mobile) {
			flex: auto;
		}
	}

	&--link {
		position: relative;
		opacity: 0;
		transition: all 0.25s $ease-in-out-quad;
		pointer-events: none;
	}

	&:hover &--link:hover,
	&--image:hover + &--link {
		opacity: 1;
		transform: translateX(0) !important;
		pointer-events: all;
	}
}

// LEFT/RIGHT ALIGNMENTS
.Avatar {
	.BubbleContainer.left & {
		flex-direction: row;

		&--link {
			transform: translateX(2px);
			&:before {
				opacity: 1;
			}
		}
	}

	.BubbleContainer.right & {
		flex-direction: row-reverse;

		&--link {
			transform: translateX(-2px);
			&:after {
				opacity: 1;
			}
		}
	}
}

.Avatar--link {
	@include font-s;
	background: $c-white;
	padding: 15px 20px;
	padding-top: $gutter * 0.5;
	padding-right: $gutter * 0.5;
	padding-bottom: $gutter * 0.5;
	padding-left: $gutter * 0.5;
	display: flex;
	align-items: center;
	border-radius: 10px;
	box-shadow: 0 20px 35px -18px rgba(0, 0, 0, 0.2);

	@include above-mobile {
		padding-top: $gutter * 0.75;
		padding-right: $gutter * 1;
		padding-bottom: $gutter * 0.75;
		padding-left: $gutter * 1;
	}

	&--title {
		display: block;
		color: $c-primary;
		margin-bottom: 4px;
	}

	&--text {
		display: block;
		color: $c-grey;
	}

	.SvgIcon {
		background: #eee;
		width: 35px;
		height: 35px;
		flex: 0 0 auto;
		border-radius: 50%;
		margin-left: 20px;
		@include above-mobile {
			width: 50px;
			height: 50px;
		}
	}

	// CSS ARROW LEFT
	&:before,
	&:after {
		content: ' ';
		right: 100%;
		top: 50%;
		border: solid transparent;
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(255, 255, 255, 0);
		border-right-color: $c-white;
		border-width: 8px;
		margin-top: -8px;
		opacity: 0;
	}

	// CSS ARROW RIGHT
	&:after {
		left: 100%;
		border-color: rgba(255, 255, 255, 0);
		border-left-color: $c-white;
	}
}

// ALWAYS SHOW LINK FOR QUOTES
.BubbleBlockquote + .Avatar .Avatar--link {
	opacity: 1;
	pointer-events: all;
}
