.BasicInput {
	position: relative;

	> label {
		@include font-base;
		line-height: 1;
		display: block;
		position: absolute;
		top: $gutter * 1.1;
		left: $gutter/1.2;
		cursor: text;
		z-index: 2;
		pointer-events: none;
		transform-origin: 0 0;
		color: lighten($c-grey, 20%);
		@include above-mobile {
			left: $gutter;
			top: $gutter * 1.2;
		}
	}

	&.select > label {
		position: static;
		margin-bottom: 0.5em;
		padding-left: 0;
		color: inherit;
		background-color: transparent;
		box-shadow: none;
		cursor: initial;
	}

	> label.has-focus,
	&.has-value > label,
	&.has-error > label,
	&:not(.has-novalue) input[value] + label {
		transform: translateY(-$gutter * 0.8) scale(0.5);
		color: $c-black;
	}

	.Input,
	textarea,
	input,
	select {
		@include font-base;
		padding-top: $gutter/1.2;
		padding-bottom: $gutter/1.2;
		padding-left: $gutter/1.2;
		padding-right: $gutter/1.2;
		border: none;
		background: $c-white;
		border-radius: 15px;
		font-size: 16px; // to prevent auto zoom on input:focus on iOS devices
		width: 100%;
		border: 2px solid transparent;

		@include above-mobile {
			padding-top: $gutter * 1;
			padding-bottom: $gutter * 1;
			padding-left: $gutter;
			padding-right: $gutter;
		}

		&:-webkit-autofill {
			box-shadow: 0 0 0 50px #fff inset;
		}

		.is-disabled,
		&[disabled] {
			opacity: 1;
			cursor: no-drop;

			& + label {
				background-color: transparent;
				box-shadow: none;
			}
		}
	}

	.Input,
	textarea,
	input,
	select {
		transition: $transition-duration-default box-shadow;
		&:focus {
			box-shadow: 0 20px 35px -18px rgba(0, 0, 0, 0.2);
		}

		&:invalid {
			box-shadow: none;
		}
	}

	.Input,
	textarea,
	input {
		hyphens: none;
	}

	.Input--select {
		position: relative;
		width: 100%;
		color: $c-black;
		color: lighten($c-grey, 20%);

		&.has-value {
			color: $c-black !important;
		}

		option[value=''][disabled] {
			display: none;
		}
	}

	.Input--selectArrow:after {
		position: absolute;
		content: '';
		top: 50%;
		right: 20px;
		width: 0;
		height: 0;
		border: 6px solid transparent;
		border-color: $c-grey transparent transparent transparent;
	}

	&.textarea {
		-webkit-overflow-scrolling: touch;
	}
}

.BasicInput.has-error {
	.Input,
	textarea,
	input,
	select {
		border-color: $c-primary;
	}

	label {
		color: $c-primary;
	}
	.Input--error {
		color: $c-primary;
		margin-top: 1em;
	}
}

// File
.BasicInput {
	.js-FileUpload {
		position: relative;
		display: inline-flex;
		flex-direction: column;
		margin: 0;
		padding: 0;
		align-items: baseline;

		@include above-mobile {
			flex-direction: row;
		}
	}

	.js-FileUpload-dropzone {
		@include font-xs;
		margin-left: 15px;

		@include mobile {
			margin-bottom: 35px;
		}

		span {
			display: block;
		}
	}

	.js-FileUpload-errorMessage {
		display: none;
		color: red;
		@include font-xs;
		margin-left: 15px;

		@include mobile {
			margin-bottom: 35px;
		}
	}

	input[type='file'] {
		@include visuallyhidden;

		& + label {
			@extend .Button, .small, .white;
			cursor: pointer;
			pointer-events: all;
			position: static;
			box-shadow: 0px 13px 35px -25px rgba(0, 0, 0, 0.1);
		}

		&[multiple] ~ .js-FileUpload-removeFiles {
			display: none !important;
		}
	}

	.js-FileUpload-removeFiles {
		position: absolute;
		top: 18px;
		right: -30px;
		display: none;
		@include mobile {
			display: none !important;
		}
	}
}

$height: 30px;
$thumb-height: 21px;
$track-height: 5px;

// colours
$upper-color: $c-white;
$lower-color: $c-primary;
$thumb-color: $c-primary;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat
	transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat
	transparent;

@function webkit-slider-thumb-shadow($i: 1) {
	$val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
	@if $i == 1 {
		@for $k from 2 through 1000 {
			$val: #{$val}, webkit-slider-thumb-shadow($k);
		}
	}
	@return $val;
}

// Range

.BasicInput {
	.labelRange--left {
		float: left;
		@include font-xs;
	}
	.labelRange--right {
		float: right;
		@include font-xs;
		opacity: 0.4;
	}
}

.BasicInput .Label--range {
	position: static;
	@include font-xs;
	color: $c-black;
	display: flex;
	justify-content: space-between;
}

.BasicInput input[type='range'] {
	background: none;
	display: block;
	appearance: none;
	width: 100%;
	margin: 0;
	padding: 0;
	height: $height;
	overflow: hidden;
	cursor: pointer;

	// ======
	// CHROME
	&::-webkit-slider-runnable-track {
		width: 100%;
		height: $height;
		background: $lower-background;
	}

	&::-webkit-slider-thumb {
		position: relative;
		appearance: none;
		height: $thumb-height;
		width: $thumb-height;
		background: $c-white;
		border-radius: 100%;
		border: 0;
		top: 50%;
		margin-top: (-$thumb-height/2);
		box-shadow: webkit-slider-thumb-shadow();
		transition: background-color 150ms;
		border: 7px solid $c-primary;
	}

	&:hover,
	&:focus {
		box-shadow: none;
	}

	// Firefox
	&::-moz-range-track,
	&::-moz-range-progress {
		width: 100%;
		height: $height;
		background: $upper-background;
	}

	&::-moz-range-progress {
		background: $lower-background;
	}

	&::-moz-range-thumb {
		appearance: none;
		margin: 0;
		height: $thumb-height;
		width: $thumb-height;
		background: $thumb-color;
		border-radius: 100%;
		border: 0;
		transition: background-color 150ms;
	}

	// Internet Exploder
	&::-ms-track {
		width: 100%;
		height: $height;
		border: 0;
		// color needed to hide track marks
		color: transparent;
		background: transparent;
	}

	&::-ms-fill-lower {
		background: $lower-background;
	}

	&::-ms-fill-upper {
		background: $upper-background;
	}

	&::-ms-thumb {
		appearance: none;
		height: $thumb-height;
		width: $thumb-height;
		background: $thumb-color;
		border-radius: 100%;
		border: 0;
		transition: background-color 150ms;
		// IE Edge thinks it can support -webkit prefixes
		top: 0;
		margin: 0;
		box-shadow: none;
	}
}
