/* HeaderNav Component Styles */
// MENU OPEN ANIMATIONS

// CLOSED
.HeaderNav {
	.HeaderNavOverlay {
		// opacity: 0;
		will-change: opacity;
		pointer-events: none;
		opacity: 0;
		visibility: hidden;
		// the normal box delays visibility transition so it doesn't hide until after the opacity transition is done
		transition: opacity 0.2s linear, visibility 0s linear 500ms;
	}

	// MAIN LINKS [ANIM ORDER: 1]
	.HeaderNavOverlay--1LevelLinks--list-item > a {
		display: inline-block;
		opacity: 0;
		transform: translateY(10px);
		will-change: transform, opacity;
	}

	// CHATBUBBLES [ANIM ORDER: 2]
	.BubbleContainer--inner > * {
		opacity: 0;
		transform: translateY(10px);
		will-change: transform, opacity;
	}

	// HEADER [ANIM ORDER: 3]
	.HeaderNavOverlay--header {
		opacity: 0;
		will-change: opacity;
	}

	// FOOTER [ANIM ORDER: 4]
	.HeaderNavOverlay--footer {
		opacity: 0;
		will-change: opacity;
	}
}

// OPEN
.HeaderNav.is-open {
	.HeaderNavOverlay {
		opacity: 1;
		visibility: visible;
		cursor: pointer;
		pointer-events: all;
		// the shown box does not delay visibility, so it shows instantly and transitions opacity
		transition: opacity 200ms linear, visibility 0s linear;
	}

	// MAIN LINKS [ANIM ORDER: 1]
	// Due to the subnav being position absolute to parent to and allow animation on open this looks a bit complicated
	.HeaderNavOverlay--1LevelLinks--list-item {
		@for $i from 1 through 5 {
			&:nth-of-type(#{$i}) > a {
				opacity: 1;
				transform: translateY(0);
				transition: all 0.3s;
				transition-delay: 0.8s + ($i / 17);
			}
		}
	}

	// CHATBUBBLES [ANIM ORDER: 2]
	.BubbleContainer--inner > * {
		opacity: 1;
		transform: translateY(0);
		transition: all 0.3s;

		@for $i from 1 through 10 {
			&:nth-of-type(#{$i}) {
				transition-delay: 1.1s + ($i / 17);
			}
		}
	}

	// HEADER [ANIM ORDER: 3]
	.HeaderNavOverlay--header {
		opacity: 1;
		transition: all 0.3s;
		transition-delay: 1.4s;
	}

	// FOOTER [ANIM ORDER: 4]
	.HeaderNavOverlay--footer {
		opacity: 1;
		transition: all 0.3s;
		transition-delay: 1.45s;
	}
}
