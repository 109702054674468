$mq-mobile-old: 375px !default;
$mq-mobile-portrait: 400px !default;
$mq-mobile-narrow: 580px !default;
$mq-mobile: 750px !default;
$mq-tablet-portrait: 1000px !default;
$mq-tablet: 1200px !default;
$mq-desktop: 1382px !default;

@mixin mobile-old {
	@media (max-width: ($mq-mobile-old - 1px)) {
		@content;
	}
}

@mixin mobile-portrait {
	@media (max-width: $mq-mobile-portrait) {
		@content;
	}
}
@mixin above-mobile-portrait {
	@media (min-width: ($mq-mobile-portrait + 1px)) {
		@content;
	}
}

@mixin mobile-narrow {
	@media (max-width: $mq-mobile-narrow) {
		@content;
	}
}
@mixin above-mobile-narrow {
	@media (min-width: ($mq-mobile-narrow + 1px)) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: $mq-mobile) {
		@content;
	}
}
@mixin above-mobile {
	@media (min-width: ($mq-mobile + 1px)) {
		@content;
	}
}

@mixin tablet-portrait {
	@media (max-width: $mq-tablet-portrait) {
		@content;
	}
}
@mixin above-tablet-portrait {
	@media (min-width: ($mq-tablet-portrait + 1px)) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: $mq-tablet) {
		@content;
	}
}
@mixin above-tablet {
	@media (min-width: ($mq-tablet + 1px)) {
		@content;
	}
}

@mixin desktop {
	@media (max-width: $mq-desktop) {
		@content;
	}
}
@mixin above-desktop {
	@media (min-width: ($mq-desktop + 1px)) {
		@content;
	}
}

// Retina screens have a 1.5 pixel ratio, not 2
@mixin retina {
	@media only screen and (min-resolution: 144dpi) {
		@content;
	}
}

// printer
@mixin print {
	@media print {
		@content;
	}
}
// printer
@mixin not-print {
	@media only screen {
		@content;
	}
}
