@mixin font($style) {
	@if $style == 'thin' {
		font-weight: 100;
	}

	@if $style == 'light' {
		font-family: $font-regular;
	}

	@if $style == 'normal' {
		font-family: $font-medium;
	}

	@if $style == 'bold' {
		font-family: $font-bold;
	}

	@if $style == 'italic' {
		font-family: $font-regular-italic;
		font-weight: normal;
	}
}

@mixin textantialiasing() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

b,strong {
	font-weight: normal;
	@include font('bold');
}
