// CONTAINER LAYOUTS FIXES
.BubbleContainer.teaser {
	display: flex;

	@include above-mobile {
		flex-direction: row;
		justify-content: flex-start;
	}

	.BubbleH2 h2 {
		@include h1;
	}

	.TeaserImage {
		@include mobile {
			@include fullWidth;
			margin-bottom: 0;
		}

		@include above-mobile {
			flex: 1;
			order: 2;
		}
	}

	.BubbleContainer--inner {
		position: relative;
		z-index: 1;
		margin-top: -65px;
		@include above-mobile {
			margin-top: 22%;
			flex: 1;
			order: 1;
		}
	}
}

// TEASER IMAGE STYLES
.TeaserImage {
	overflow: hidden;
	position: relative;
	margin-bottom: $spacing * 2;

	@include mobile {
		@include fullWidth;
		margin-bottom: 0;
	}

	img,
	video {
		display: block;
		width: 100%;

		@include above-mobile {
			border-radius: 30px;
		}
	}

	video {
		object-fit: cover;
	}
}

// =================
// =================
// TEASER IMAGE RIGHT / TEXT LEFT
// Teaser left is slighly bigger than right aligned teaser

.BubbleContainer.teaser.left {
	@include above-tablet {
		// margin-left: 90px;
		margin-right: 90px;
	}

	.TeaserImage {
		@include above-mobile {
			margin-left: calc(-100% + 270px);
			order: 2;
		}
		@include above-tablet {
			margin-left: calc(-100% + 450px);
		}
	}

	.BubbleContainer--inner {
		order: 1;
		@include above-mobile {
			margin-top: 22%;
		}
		// CAN'T PUT MAX WIDHT ON PARENT SO FORCING ALL CHILDREN TO HAVE A MAX WIDTH
		& > * {
			@include above-mobile {
				max-width: 80%;
			}

			@include above-tablet {
				max-width: 60%;
			}
		}

		& > .BubbleH2 {
			max-width: none;

			@include above-tablet-portrait {
				max-width: 65%;
			}
		}
	}

	.TeaserImage + .BubbleContainer--inner {
		position: relative;
	}
}

// =================
// =================
// TEASER IMAGE LEFT / TEXT RIGHT
.BubbleContainer.teaser.right {
	@include above-tablet {
		margin-left: 45px;
		margin-right: 135px;
	}

	.TeaserImage {
		order: 1;
		@include above-mobile {
			margin-right: calc(-100% + 360px);
			// margin-left: 90px;
		}

		@include above-tablet {
			margin-right: calc(-100% + 480px);
			margin-left: 90px;
		}
	}

	.BubbleContainer--inner {
		order: 2;

		@include above-mobile {
			margin-top: 15%;
		}

		& > * {
			@include above-mobile {
				max-width: 80%;
			}

			@include above-tablet {
				max-width: 70%;
			}
		}

		& > .BubbleH2 {
			max-width: none;

			@include above-tablet-portrait {
				max-width: 65%;
			}
		}
	}
}
// ================= ================= =================
// ================= ================= =================
// STORIES SPECIFIC TEASERS

.page--stories .BubbleContainer.teaser .BubbleH2 h2 {
	@include h2;
}

// =================
// =================
// TEASER IMAGE LEFT / TEXT RIGHT
.page--stories .BubbleContainer.teaser.right .BubbleContainer--inner {
	@include above-mobile {
		margin-top: 22%;
	}

	& > .BubbleH2 {
		@include above-mobile {
			max-width: 72%;
		}

		@include above-tablet {
			max-width: 62%;
		}
	}
}

// =================
// =================
// TEASER IMAGE RIGHT / LEFT RIGHT
.page--stories .BubbleContainer.teaser.left .BubbleContainer--inner {
	@include above-mobile {
		margin-top: 26%;
	}

	& > .BubbleH2 {
		@include above-mobile {
			max-width: 72%;
		}

		@include above-tablet {
			max-width: 52%;
		}
	}
}
