/* Image Component Styles */
img[data-sizes],
.no-lazyload {
	display: block;
	//width: 100%;
	user-select: none;
}

.Image[data-object-fit-polyfilled] {
	@include visuallyhidden;
}
