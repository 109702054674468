/*  
    BubbleH1 Component Styles
    @animatable true
*/

.BubbleH1 {
    @include Bubble;
    @include mobile {
        width: 100%;
    }    

    h1 {
        @include h1;

        @include mobile {
            @include hyphenate;
        }        
    }

    .BubbleContainer.left & {
        @include above-mobile {
            margin-right: -4vw;
        }
    }

    .BubbleContainer.right & {
        @include above-mobile {
            margin-left: -4vw;
        }
    }
}