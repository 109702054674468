/* Text Component Styles */

// Element styling
.Text {
	@include font-base;
	@include font(light);

	h2 {
		@include font-l;
		@include font(bold);
		margin-bottom: 1em;
		@include hyphenate;
	}

	p {
		margin-bottom: 1em;
	}

	b,
	strong {
		@include strong;
	}

	i,
	em {
		font-style: italic;
	}

	a {
		position: relative;
		color: $c-primary;
		text-decoration: none;
		cursor: pointer;
		&:after,
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 2px;
			bottom: -4px;
			left: 0;
			background: rgba($c-primary, 0.2);
			z-index: 2;
			transition: all $transition-duration-slow;
		}

		&:after {
			width: 0;
		}

		&:hover:after {
			width: 100%;
			background: rgba($c-primary, 1);
		}
	}

	ul {
		margin-bottom: $gutter * 2;
		@include above-mobile {
			margin-bottom: $gutter * 3;
		}
	}

	p + ul {
		margin-top: $gutter * 2;
		@include above-mobile {
			margin-top: $gutter * 3;
		}
	}

	ul li {
		@include font-s;
		position: relative;
		padding-left: 3em;
		padding-top: 0.3em;
		margin-bottom: 1em;
		@include strong;

		&:last-child {
			margin: 0;
		}

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			background: $c-white;
			top: 0;
			left: 0;
			width: calc(0.7em + #{$gutter});
			height: calc(0.7em + #{$gutter});
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='#{$c-primary}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.4997544,3.46816116 C20.132606,2.8389053 21.1384845,3.64354925 20.6635488,4.39912866 L11.2349774,19.3991287 C10.949922,19.8526259 10.2950781,19.869735 9.98673072,19.4317416 L3.38673072,10.0567416 C2.92574993,9.40193932 3.67425241,8.57730835 4.37050706,8.97290758 L10.4751722,12.4414673 L19.4997544,3.46816116 Z' stroke=''/%3E%3C/svg%3E%0A");
			background-size: 0.7em auto;
			background-repeat: no-repeat;
			background-position: 46% 50%;
			border-radius: 50px;
		}
	}
}
