/*  
    BubbleImage Component Styles
    @animatable true
 */

.BubbleImage {
	@include BubbleMedia;

	a.chocolat-image {
		cursor: zoom-in;
	}

	figcaption {
		@include font-s;
		padding-top: $gutter * 0.5;
		padding-right: $gutter * 0.8;
		padding-bottom: $gutter * 0.4;
		padding-left: $gutter * 0.8;
		background: $c-black;
		background: rgba($c-black, 0.4);
		color: $c-white;
		border-radius: 15px;
		position: absolute;
		top: 20px;
		right: 20px;

		@include above-mobile {
			padding-top: $gutter * 1;
			padding-right: $gutter * 1.3;
			padding-bottom: $gutter * 0.9;
			padding-left: $gutter * 1.3;
		}
	}
}

// THIS DIRTY FIX IS TO BETTER ALIGN TEAM PORTRAITS AND CUT OF BLACK ABOVE IMAGES
// RATHER THAN MANUALLY CROPPING 200+ IMAGES
.page--team-members .BubbleContainer .BubbleImage {
	position: relative;
	height: 0;
	padding-bottom: 55%;
	overflow: hidden;
	max-width: 500px;
	width: 100%;

	img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		font-family: 'object-fit: cover'; // for polyfill
	}
}

.BubbleImage .Image.lazyload {
	@include mobile {
		width: calc(100vw - 30px);
	}
}
