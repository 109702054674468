/*  
    Button Component Styles
    @animatable true
*/
// BASE styling
.Button {
	@include font-base;
	padding-top: $gutter * 0.5;
	padding-right: $gutter * 0.5;
	padding-bottom: $gutter * 0.5;
	padding-left: $gutter * 1;
	background: $c-primary;
	color: $c-white;
	border-radius: 100px;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
	box-shadow: 0 20px 35px -18px rgba(0, 0, 0, 0.2);

	.Button--Icon {
		background: $c-primary--dark;
		width: $gutter * 2;
		height: $gutter * 2;
		flex: 0 0 auto;
		border-radius: 50%;
		margin-left: 20px;
		transition: margin $transition-duration-default;

		@include above-tablet-portrait {
			width: $gutter * 2.5;
			height: $gutter * 2.5;
		}
	}

	&:hover {
		transition: box-shadow 0.1s;
		box-shadow: 0 20px 35px -18px rgba(0, 0, 0, 0.3);

		&:not(.reverse):not(.icon-only) .Button--Icon {
			margin-left: 25px;
		}
	}
}

.Button--Icon {
	display: flex;
	align-items: center;
	justify-content: center;

	.SvgIcon > svg {
		fill: $c-white;
	}

	.white & {
		.SvgIcon > svg {
			fill: $c-primary;
		}
	}

	.grey & {
		.SvgIcon > svg {
			color: $c-grey;
		}
	}
}

.Button.small {
	padding-top: $gutter * 0.5;
	padding-right: $gutter * 0.5;
	padding-bottom: $gutter * 0.5;
	padding-left: $gutter * 1;

	.Button--Icon {
		width: $gutter * 2;
		height: $gutter * 2;

		svg {
			height: 1em;
		}
	}
}

.Button.reverse {
	flex-direction: row-reverse;
	padding-top: $gutter * 0.5;
	padding-right: $gutter * 1;
	padding-bottom: $gutter * 0.5;
	padding-left: $gutter * 0.5;

	.Button--Icon {
		margin-right: 20px;
		margin-left: 0;
		transform: translate3d(0, 0, 0) rotate(180deg);
	}

	&:hover .Button--Icon {
		margin-right: 25px;
	}
}

// BUTTON -- No background, no padding
.Button.naked {
	background: none;
	padding: 0;
	min-width: auto;
	box-shadow: none;

	.Button--Icon {
		background: $c-grey--medium;
	}
}

// BUTTON -- White
.Button.white {
	background: $c-white;
	color: $c-primary;

	.Button--Icon {
		background: $c-grey--light;
	}
}

.Button.icon-only {
	padding: 0;
	width: $gutter * 2.8;
	height: $gutter * 2.8;
	border-radius: $gutter * 1.2;
	justify-content: center;

	@include above-tablet-portrait {
		width: $gutter * 3.6;
		height: $gutter * 3.6;
		border-radius: $gutter * 1.5;
	}

	.Button--Icon {
		margin: 0;
		background: none;
	}
}

.Button.right {
	align-self: flex-end;
}

.Button.left {
	align-self: flex-start;
}
