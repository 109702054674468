/* Slider Component Styles */
// Base layout
.Slider {
	overflow: hidden;
	margin: 0 -15px $spacing * 2;
	padding: 0 20px;

	@include above-mobile {
		padding: 0;
		margin-left: 0;
		margin-right: 0;
		overflow: visible;
	}

	// SAFARI HACK
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) and (min-width: 750px) {
			overflow: hidden;
		}
	}

	.BubbleContainer {
		padding-bottom: $gutter;
	}

	.Slider--inner {
		padding-bottom: 20px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
}

// Slider item styling
.Slider--item {
	position: relative;
	background: $c-white;
	border-radius: 20px;
	height: 0;
	overflow: hidden;
	padding-bottom: 105%;

	@include above-mobile {
		border-radius: 30px;
	}

	&-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: $gutter * 1;
		display: flex;
		flex-direction: column;
	}
}
// Slider item Elements: Image
.Slider--item .Image {
	width: 70%;
	height: 80px;
	object-fit: contain;
	margin: auto;
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%);

	@include above-mobile {
		height: 180px;
	}
}

// Slider item Elements: Title
.Slider--item--title {
	@include font-s;
	color: $c-primary;
}

// Slider item Elements: Links on hover
.Slider--item--links {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: $gutter * 0.5;
	background: rgba(0, 0, 0, 0.6);
	opacity: 0;
	transition: 0.5s opacity;
	border-radius: 20px;

	@include above-mobile {
		padding: $gutter * 2;
		border-radius: 30px;
	}

	a {
		opacity: 0;
		transform: translateY(5px);
		transition: $transition-duration-default all;
		will-change: transform, opacity;

		@for $i from 1 through 5 {
			&:nth-child(#{$i}) {
				transition-delay: $i * 0.1 + s;
			}
		}
	}

	a:last-child {
		margin-bottom: 0;
	}
}

// Slider item Elements: Hover state links
.Slider--item:not([aria-hidden]):hover {
	.Slider--item--links {
		opacity: 1;
	}

	.Slider--item--links a {
		opacity: 1;
		transform: translateY(0);
	}
}

// ==========================================
// ==========================================
// ==========================================
// CAROUSEL ENABLED
.Slider .Slider--inner[data-js='Slider'] {
	.Slider--item {
		width: calc(70% - 20px);
		margin: 0 10px 20px;
		padding-bottom: 92%;

		@include above-mobile {
			width: calc(50% - 20px);
			padding-bottom: 60%;
		}

		@include above-tablet-portrait {
			padding-bottom: 35%;
			width: calc(33.33333% - 20px);
		}
	}
}

.Slider .flickity-prev-next-button {
	background: $c-white;
	width: 60px;
	height: 60px;
	border-radius: 25px;
	margin-top: 30px;
	box-shadow: 0 10px 25px -18px rgba(0, 0, 0, 0.2);
	margin-left: 10px;

	@include above-mobile {
		box-shadow: 0 20px 35px -18px rgba(0, 0, 0, 0.2);
		width: 72px;
		height: 72px;
		border-radius: 30px;
	}

	&:disabled {
		opacity: 0.3;
		box-shadow: none;
	}

	&.previous {
	}

	svg {
		width: 24px;
		fill: $c-primary;
		transform: rotate(180deg);
	}

	&:hover svg {
		transition: $transition-duration-default transform;
		transform: scale(1.1) rotate(180deg);
	}
}

/*! Flickity v2.0.5
http://flickity.metafizzy.co
---------------------------------------------- */
[data-js-slider]:not(.is-slider-ready) {
	.js-slider--next,
	.js-slider--prev {
		visibility: hidden;
	}
}

.flickity-viewport {
	// overflow: hidden;
	position: relative;
	height: 100%;
	flex: 0 0 100%;

	.TeaserSlider & {
		z-index: 1;
	}
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

.flickity-enabled {
	/* draggable = active */
	&.is-draggable {
		tap-highlight-color: transparent;
		user-select: none;
		* {
			-webkit-user-drag: none;
		}
		.flickity-viewport {
			cursor: move;
			cursor: -webkit-grab;
			cursor: grab;
		}

		.flickity-viewport.is-pointer-down {
			cursor: -webkit-grabbing;
			cursor: grabbing;
		}
	}

	&.is-dragging .js-slider--slide {
		pointer-events: none;
	}
}
