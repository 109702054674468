/*  
    BubbleVideo Component Styles
    @animatable true
*/

.BubbleVideoWrapper {
	@include Bubble;
	padding: 0 !important;
	overflow: hidden;
}

.BubbleVideo {
	position: relative;
	overflow: hidden;
	background: $c-black;

	img {
		transition: 0.3s opacity;
		@include mobile {
			width: 100%;
		}
	}

	&:hover img {
		opacity: 0.8;
		cursor: pointer;
	}

	.BubbleVideo--playbutton {
		position: absolute;
		width: 64px;
		height: 64px;
		margin-left: -32px;
		margin-top: -32px;
		display: block;
		top: 50%;
		left: 50%;
		border-radius: 100%;
		background-color: $c-white;
		text-indent: -9999px;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -8px;
			margin-left: -6px;
			border-top: 9px solid transparent;
			border-bottom: 9px solid transparent;
			border-left: 16px solid $c-primary;
			transform: scale(1);
			transition: 0.2s transform;
		}

		&:hover:before {
			transform: scale(1.1);
		}
	}
}

.PlayerWrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	background: $c-white;
	display: flex;
	align-items: center;
	justify-content: center;
	display: none;

	.BubbleVideo--closebutton {
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: rgba(0, 0, 0, 0.2);

		@include above-mobile {
			width: 50px;
			height: 50px;
		}

		.Icon {
			transform: scale(1.1);
			display: block;
		}
	}

	.plyr--video {
		width: 90%;
	}
}

.BubbleVideoWrapper.show {
	transition: none !important; // Since we animate this element we need to reset these
	transform: none !important; // values when its open
	.PlayerWrapper {
		display: flex;
	}
}
